@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'test';
    src: url('../src/assets/azonix/Azonix.otf') format('opentype'),
    /* Fügen Sie weitere Formate hinzu, wenn nötig */
  }
  
  html {
    background-color: #191919;
  }
  
  body {
    margin: 0;
    font-family: 'test', sans-serif;
    font-size: 16px;
  }
  